import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  CenteredContainer,
  ContactForm,
  CookieWarning,
  Link,
} from '../components';
import { iconMenuImg, logoOnWhiteImg, logoWebeeImg } from '../images';
import { NavbarContext, ScrollContext, useNavbarScrollY } from './hooks';
import {
  Link as I18nextLink,
  Trans,
  useTranslation,
} from 'gatsby-plugin-react-i18next';
import FBIcon from './fb_icon.inline.svg';
import InstagramIcon from './instagram_icon.inline.svg';
import PhoneIcon from './phone_icon.inline.svg';
import MessageIcon from './message_icon.inline.svg';
import classNames from 'classnames';
import gradientTop from '../images/gradient_top.jpg';
import { Seo } from '@orkisz/gatsby-seo-headers';

export interface LayoutProps {
  children: React.ReactNode;
  path: string;
}

const renderMainMenuItem = (item: React.ReactElement, title: string) => {
  if (title === 'contact') {
    return React.cloneElement(item, {
      className: 'button-blue',
    });
  }
  return item;
};

const renderFooterMenuItem = (item: React.ReactElement) => {
  return React.cloneElement(item, {
    className: 'hover:!text-white hover:!underline-offset-4 mb-1 block',
  });
};

function Layout({ children, path }: LayoutProps) {
  const { t } = useTranslation();
  const [navbarTransparent, setNavbarTransparent] = useState(false);
  const [canScroll, setCanScroll] = useState(true);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const mobileMenuTrigger = useRef<HTMLButtonElement>(null);
  const { alpha: navbarAlpha, shadowVertical: navbardShadowVertical } =
    useNavbarScrollY();

  const windowClickHandler = useCallback(
    (event: Event) =>
      setMobileMenuOpen(
        !!mobileMenuTrigger.current?.contains(event.target as any)
      ),
    [setMobileMenuOpen]
  );

  useEffect(() => {
    window.addEventListener('click', windowClickHandler);
    return () => {
      window.removeEventListener('click', windowClickHandler);
    };
  }, []);

  const NavList = useCallback(
    ({
      className,
      renderItem,
    }: {
      className?: string;
      renderItem?: (
        item: React.ReactElement,
        title: string
      ) => React.ReactElement;
    }) => {
      renderItem ??= (item) => item;
      return (
        <ul role="list" className={className}>
          <li>
            {renderItem(
              <Link className="font-bold" to="/branding">
                {t('layout.header.nav.branding')}
              </Link>,
              'branding'
            )}
          </li>
          <li>
            {renderItem(
              <Link className="font-bold" to="/web-design">
                {t('layout.header.nav.www-design')}
              </Link>,
              'www'
            )}
          </li>
          <li>
            {renderItem(
              <Link className="font-bold" to="/#contact-form">
                {t('layout.header.nav.contact')}
              </Link>,
              'contact'
            )}
          </li>
        </ul>
      );
    },
    []
  );

  const navbarBoxShadow = `0px 9px ${navbardShadowVertical}px -9px rgba(66, 68, 90, 1)`;

  if (path === '/privacy-policy/') {
    return <main>{children}</main>;
  }
  return (
    <>
      <ScrollContext.Provider value={{ setCanScroll }}>
        <NavbarContext.Provider
          value={{
            transparent: navbarTransparent,
            setTransparent: setNavbarTransparent,
          }}
        >
          <Helmet
            htmlAttributes={{
              class: 'scroll-smooth',
            }}
            bodyAttributes={{
              class: classNames('bg-white', canScroll ? '' : 'overflow-hidden'),
            }}
          ></Helmet>
          <CookieWarning />
          <Seo />
          <div className="relative mx-auto max-w-[1920px] overflow-hidden font-sans text-main">
            <div
              className="fixed top-0 z-50 mx-auto h-[var(--mobile-menu-height)] w-full max-w-[1920px] md:h-[var(--desktop-menu-height)] bmd:!bg-[rgba(255,255,255,1)] bmd:!shadow-[0px_10px_9px_-9px_rgba(66,68,90,1)]"
              style={{
                backgroundColor: `rgba(255,255,255,${
                  navbarTransparent ? navbarAlpha : 1
                })`,
                boxShadow: navbarBoxShadow,
                WebkitBoxShadow: navbarBoxShadow,
                MozBoxShadow: navbarBoxShadow,
              }}
            >
              <CenteredContainer className="mx-auto flex justify-between md:py-6 w6xl:max-w-[77rem]">
                <I18nextLink className="inline-flex items-center" to="/">
                  <img
                    className="my-2 max-h-[40px] max-w-[207px] md:my-0 md:max-h-[55px]"
                    src={logoWebeeImg}
                    alt="Logo"
                  />
                </I18nextLink>
                <nav className="hidden items-center md:flex">
                  <NavList
                    className="flex content-center items-center gap-10"
                    renderItem={renderMainMenuItem}
                  />
                </nav>
                <button className="md:hidden" ref={mobileMenuTrigger}>
                  <img className="mx-auto w-1/2" src={iconMenuImg} />
                </button>
                <nav
                  className="absolute top-[56px] left-1/4 block w-[calc(50%+2rem)] bg-white p-4 md:hidden"
                  style={{ display: mobileMenuOpen ? '' : 'none' }}
                >
                  <NavList className="flex flex-col content-center gap-6" />
                </nav>
              </CenteredContainer>
            </div>
            <main className="mt-[var(--mobile-menu-height)] md:mt-[var(--desktop-menu-height)]">
              {children}
            </main>
            <footer>
              <section
                className="relative pt-14 pb-16 md:pt-28"
                id="contact-form"
              >
                <div
                  className="absolute top-0 -z-10 h-full w-full bg-cover bg-right"
                  style={{
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 22%), url("${gradientTop}")`,
                  }}
                  aria-hidden="true"
                ></div>
                <CenteredContainer>
                  <h2 className="font-secondary text-2xl font-semibold text-header md:text-4xl">
                    {t('layout.footer.contact.header')}
                  </h2>
                  <p className="mt-4 mb-9 text-sm md:max-w-3xl md:text-lg">
                    <Trans t={t} i18nKey="layout.footer.contact.text" />
                  </p>
                  <ContactForm />
                </CenteredContainer>
              </section>
              <section className="bg-[color:rgb(var(--color-navy-dark))] pt-8 pb-4 text-white md:pt-16">
                <CenteredContainer className="grid-cols-3 gap-6 md:grid">
                  <div className="col-span-3">
                    <img
                      className="max-w-[250px]"
                      src={logoOnWhiteImg}
                      alt="Logo"
                    />
                  </div>
                  <div className="mt-4 text-sm md:mt-0 md:text-base">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('layout.footer.about'),
                      }}
                    />
                    <p
                      className="mt-4"
                      dangerouslySetInnerHTML={{
                        __html: t('layout.footer.watch-us'),
                      }}
                    />
                    <p className="mt-4 flex gap-2">
                      <a
                        href="https://www.facebook.com/profile.php?id=100088611814355"
                        target="_blank"
                      >
                        <FBIcon title="Facebook" />
                      </a>
                      <a
                        href="https://www.instagram.com/katy_loves_illustrations/"
                        target="_blank"
                      >
                        <InstagramIcon title="Instagram" />
                      </a>
                    </p>
                  </div>
                  <div className="mt-8 md:mt-0">
                    <p className="uppercase">
                      {t('layout.footer.contact.contact')}
                    </p>
                    <ul role="list">
                      <li className="mt-4">
                        <a
                          className="flex items-center"
                          href="mailto:kontakt@weebee.pl"
                        >
                          <MessageIcon className="mr-[10px]" />
                          kontakt@weebee.pl
                        </a>
                      </li>
                      <li className="mt-4">
                        <a
                          className="flex items-center"
                          href="tel:+48509568702"
                        >
                          <PhoneIcon className="mr-[10px]" />
                          (+48) 509 568 702
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-8 text-sm md:mt-0 md:text-base">
                    <p className="mb-3 uppercase">
                      {t('layout.footer.sitemap')}
                    </p>
                    <nav>
                      <NavList renderItem={renderFooterMenuItem} />
                    </nav>
                  </div>
                  <div className="col-span-3 mt-10 text-center text-xs md:mt-24 md:text-base">
                    Copyright <sup>&copy;</sup>weebee 2023
                  </div>
                </CenteredContainer>
              </section>
            </footer>
          </div>
        </NavbarContext.Provider>
      </ScrollContext.Provider>
    </>
  );
}

export default Layout;
