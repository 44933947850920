module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-layout-virtual-bf48f78733/0/cache/gatsby-plugin-layout-npm-3.24.0-da8689f0da-2f498589f0.zip/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-6aab5b67bc/0/cache/gatsby-plugin-google-gtag-npm-5.1.0-4e4d1f9c19-4be7c9c17b.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-0LLRQEB56S"],"pluginConfig":{"respectDNT":true}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-cb761bdfbb/0/cache/gatsby-plugin-manifest-npm-4.24.0-3629f3f328-5040a3844e.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"524082904eea9f4806ad9d2f1ed641ca"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-react-i18next-virtual-5e14bdee4e/0/cache/gatsby-plugin-react-i18next-npm-2.0.4-acb1d22f76-0544569092.zip/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["pl"],"defaultLanguage":"pl","siteUrl":"https://weebee.pl","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-08512f6ef6/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
