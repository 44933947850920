import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback, useRef, useState } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { EMAIL_VALIDATION_RULES } from '../../../shared/consts';
import { Checkbox } from '../checkbox';
import { Input } from '../input';
import { TextArea } from '../text-area';

export interface ContactFormProps
  extends React.HTMLAttributes<HTMLFormElement> {
  onFormSubmitted?: () => void;
}

function ContactForm({
  className,
  onFormSubmitted,
  children,
  ...rest
}: ContactFormProps) {
  const { t } = useTranslation();
  const fakeInputRef = useRef<HTMLInputElement>(null);
  const { handleSubmit, register, reset, formState } = useForm();
  const [token, setToken] = useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(0);

  const handleVerify = useCallback(
    (token: string) => {
      setToken(token);
    },
    [setToken]
  );

  const onSubmit = async (data: any) => {
    /* BOTs will try to fill any input, but we want this to be empty :) */
    if (fakeInputRef.current?.value) {
      return;
    }
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('service', data.service);
    formData.append('message', data.message);
    formData.append('date', data.company);
    formData.append('token', token);
    try {
      setToken('');
      const response = await fetch(process.env.GATSBY_EMAIL_API_URL as any, {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      reset();
      onFormSubmitted?.();
      alert(t('components.contact-form.after-submitting'));
    } catch (e) {
      console.error(e);
      alert(t('components.contact-form.after-submitting-error'));
    } finally {
      setRefreshReCaptcha((v) => +!v);
    }
  };

  const errors = Object.entries(formState.errors);

  const getErrorMessage = useCallback(
    (type: string) => t(`components.contact-form.errors.${type}`),
    [t]
  );

  return (
    <>
      <form
        className={classNames(
          'flex grid-cols-3 flex-col gap-x-12 gap-y-4 md:grid md:gap-y-9',
          className
        )}
        onSubmit={handleSubmit(onSubmit)}
        {...rest}
      >
        <Input
          id="name-input"
          label={`${t('components.contact-form.name')}*`}
          {...register('name', {
            required: EMAIL_VALIDATION_RULES.name.required,
            maxLength: EMAIL_VALIDATION_RULES.name.maxLength,
          })}
        />
        <Input
          id="email-input"
          label={`${t('components.contact-form.email')}*`}
          {...register('email', {
            required: EMAIL_VALIDATION_RULES.email.required,
            maxLength: EMAIL_VALIDATION_RULES.email.maxLength,
            pattern: EMAIL_VALIDATION_RULES.email.regex,
          })}
        />
        <Input
          id="phone-input"
          label={t('components.contact-form.phone')}
          {...register('phone', {
            required: EMAIL_VALIDATION_RULES.phone.required,
            maxLength: EMAIL_VALIDATION_RULES.phone.maxLength,
          })}
        />
        <div className="col-span-3">
          <div className="w-full">{t('components.contact-form.service')}*</div>
          <ul className="mt-2 grid grid-cols-[repeat(auto-fit,12rem)] justify-items-start gap-y-4">
            <li>
              <Checkbox
                id="webdesign-input"
                label={t('components.contact-form.web-design')}
                {...register('service', {
                  required: EMAIL_VALIDATION_RULES.service.required,
                })}
                value="webdesign"
              />
            </li>
            <li>
              <Checkbox
                id="logo-input"
                label={t('components.contact-form.logo')}
                {...register('service', {
                  required: EMAIL_VALIDATION_RULES.service.required,
                })}
                value="logo"
              />
            </li>
            <li>
              <Checkbox
                id="branding-input"
                label={t('components.contact-form.branding')}
                {...register('service', {
                  required: EMAIL_VALIDATION_RULES.service.required,
                })}
                value="branding"
              />
            </li>
            <li>
              <Checkbox
                id="rebranding-input"
                label={t('components.contact-form.rebranding')}
                {...register('service', {
                  required: EMAIL_VALIDATION_RULES.service.required,
                })}
                value="rebranding"
              />
            </li>
            <li>
              <Checkbox
                id="socialmedia-input"
                label={t('components.contact-form.social-media')}
                {...register('service', {
                  required: EMAIL_VALIDATION_RULES.service.required,
                })}
                value="socialmedia"
              />
            </li>
            <li>
              <Checkbox
                id="otherquestion-input"
                label={t('components.contact-form.other-question')}
                {...register('service', {
                  required: EMAIL_VALIDATION_RULES.service.required,
                })}
                value="otherquestion"
              />
            </li>
          </ul>
        </div>
        <TextArea
          id="message-input"
          className="col-span-3"
          autoHeight
          label={`${t('components.contact-form.message')}*`}
          {...register('message', {
            required: EMAIL_VALIDATION_RULES.message.required,
            maxLength: EMAIL_VALIDATION_RULES.message.maxLength,
          })}
        />
        <Input
          id="date-input"
          label={`${t('components.contact-form.date')}`}
          {...register('date', {
            required: EMAIL_VALIDATION_RULES.date.required,
            maxLength: EMAIL_VALIDATION_RULES.date.maxLength,
          })}
        />
        <div className="col-span-2" />
        {!!errors.length && (
          <ul className="col-span-3 text-red-700">
            {errors.map(([name, err], idx) => (
              <li key={idx}>
                {t(`components.contact-form.${name}`)}:{' '}
                {getErrorMessage(err?.type as any)}
              </li>
            ))}
          </ul>
        )}
        <div className="col-span-3">
          <button
            className="button-primary self-center justify-self-start md:self-start"
            type="submit"
            disabled={!token}
          >
            {t('components.contact-form.submit')}
          </button>
          <span
            className="mt-4 block self-center text-xs prose-a:underline md:ml-4 md:mt-0 md:inline"
            dangerouslySetInnerHTML={{
              __html: t('components.contact-form.privacy-policy'),
            }}
          />
        </div>
        <input className="h-0 w-0" type="text" ref={fakeInputRef} />
      </form>
      <GoogleReCaptcha
        onVerify={handleVerify}
        refreshReCaptcha={refreshReCaptcha}
        action="email"
      />
    </>
  );
}

export default ContactForm;
