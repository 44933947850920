import classNames from 'classnames';
import React, {
  FormEvent,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';
import { InputWrapper } from '../input-wrapper';

export interface TextAreaProps
  extends React.HTMLAttributes<HTMLTextAreaElement> {
  autoHeight?: boolean;
  label: React.ReactNode;
}

const TextArea = React.forwardRef(
  (
    { autoHeight, className, onInput, label, ...rest }: TextAreaProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ) => {
    const internalRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(
      ref,
      () => internalRef.current
    );

    const onInputHandler = useCallback(
      (event: FormEvent<HTMLTextAreaElement>) => {
        if (autoHeight && internalRef.current) {
          internalRef.current.style.height = 'auto';
          internalRef.current.style.height = `${internalRef.current.scrollHeight}px`;
        }
        onInput?.(event);
      },
      [onInput, autoHeight]
    );

    const { id } = rest;

    return (
      <InputWrapper
        className={classNames('input-wrapper', className)}
        label={label}
        inputId={id}
      >
        <textarea
          className="resize-none"
          ref={internalRef}
          {...rest}
          onInput={onInputHandler}
          aria-labelledby={`${id}-label`}
        />
      </InputWrapper>
    );
  }
);

export default TextArea;
