import { EMAIL_REGEX } from './regex';

export const EMAIL_VALIDATION_RULES = {
  name: {
    required: true,
    maxLength: 100,
  },
  email: {
    required: true,
    maxLength: 200,
    regex: EMAIL_REGEX,
  },
  phone: {
    required: false,
    maxLength: 100,
  },
  message: {
    required: true,
    maxLength: 5000,
  },
  date: {
    required: false,
    maxLength: 100,
  },
  service: {
    required: true,
    arrayItemsLength: 50,
  },
};
