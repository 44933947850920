import classNames from 'classnames';
import React from 'react';
import logo from './totu_sign.png';

export interface LogoPrefixedProps {
  children?: React.ReactNode;
  className?: string;
}

function LogoPrefixed({ children, className }: LogoPrefixedProps) {
  return (
    <div className={classNames('flex align-baseline', className)}>
      <img className="mr-3 h-6 flex-shrink-0" src={logo} alt="Logo" />
      <div>{children}</div>
    </div>
  );
}

export default LogoPrefixed;
