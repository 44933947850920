import { useCallback, useEffect, useState } from 'react';

export interface NavbarScrollYData {
  alpha: number;
  shadowVertical: number;
}

const NAVBAR_OPACITY_SPAN_MAX = 150;

const getAlphaFromScrollY = () =>
  window.scrollY > NAVBAR_OPACITY_SPAN_MAX
    ? 1
    : +(window.scrollY / NAVBAR_OPACITY_SPAN_MAX).toFixed(2);

const getShadowVerticalFromScrollY = () =>
  window.scrollY > NAVBAR_OPACITY_SPAN_MAX
    ? 9
    : Math.ceil((window.scrollY / NAVBAR_OPACITY_SPAN_MAX) * 9);

export function useNavbarScrollY(): NavbarScrollYData {
  const [alpha, setAlpha] = useState(0);
  const [shadowVertical, setShadowVertical] = useState(0);

  const handleScroll = useCallback(() => {
    setAlpha(getAlphaFromScrollY());
    setShadowVertical(getShadowVerticalFromScrollY());
  }, [setAlpha, setShadowVertical]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    requestAnimationFrame(() => {
      setAlpha(getAlphaFromScrollY());
      setShadowVertical(getShadowVerticalFromScrollY());
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return {
    alpha,
    shadowVertical,
  };
}
