import React from 'react';

export interface HomepageReasonProps {
  text: string;
  title: string;
  imgSrc: string;
}

function HomepageReason({ text, title, imgSrc }: HomepageReasonProps) {
  return (
    <div className="flex flex-row items-start gap-6">
      <figure className="flex h-14 w-14 shrink-0 items-center justify-center rounded-full bg-[color:#F0F3F8]">
        <img className="h-8 w-8" src={imgSrc} alt={title} />
      </figure>
      <div>
        <div className="font-header font-semibold text-header md:text-2xl">
          {title}
        </div>
        <div
          className="mt-4 max-w-[16rem] text-sm md:mt-6 md:text-base"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
}

export default HomepageReason;
