import React from 'react';

export interface NavbarContextContract {
  transparent: boolean;
  setTransparent: (isTransparent: boolean) => void;
}

const errorFn = () =>
  new Error('wrap your content with NavbarContextContract.Provider');

export const NavbarContext = React.createContext<NavbarContextContract>({
  get transparent(): never {
    throw errorFn();
  },
  setTransparent: () => {
    throw errorFn();
  },
});
