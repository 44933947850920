import classNames from 'classnames';
import React from 'react';

interface SectionProps {
  children?: React.ReactNode;
  className?: string;
}

function CenteredContainer({ children, className }: SectionProps) {
  return (
    <div className={classNames('max-w-fw px-4 sm:px-6 md:mx-auto', className)}>
      {children}
    </div>
  );
}

export default CenteredContainer;
