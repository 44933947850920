import React from 'react';

export interface InputWrapperProps {
  className?: string;
  labelClasses?: string;
  children: React.ReactNode;
  label: React.ReactNode;
  inputId?: string;
}

function InputWrapper({
  children,
  label,
  labelClasses,
  className,
  inputId,
}: InputWrapperProps) {
  return (
    <div className={className}>
      <label className={labelClasses} htmlFor={inputId} id={`${inputId}-label`}>
        {label}
      </label>
      {children}
    </div>
  );
}

export default InputWrapper;
