import classNames from 'classnames';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-react-i18next';
import React from 'react';

export interface PortfolioItemProps {
  portfolio: string;
  title: React.ReactNode;
  description: React.ReactNode;
  className?: string;
  gatsbyImage: IGatsbyImageData;
}

const PortfolioItem = ({
  portfolio,
  title,
  description,
  className,
  gatsbyImage,
}: PortfolioItemProps) => {
  return (
    <Link
      className={classNames(
        'group relative flex items-end overflow-hidden p-8',
        className
      )}
      role="button"
      to={`portfolio/${portfolio}`}
    >
      <GatsbyImage
        className="!absolute top-0 bottom-0 left-0 right-0 transition-transform duration-500 group-hover:scale-110"
        image={gatsbyImage}
        alt={portfolio}
      />
      <div
        className="absolute top-1/3 bottom-0 left-0 right-0 bg-cover bg-center bg-no-repeat opacity-100 transition-opacity duration-500 group-hover:opacity-100 md:opacity-0"
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.9) 50%, rgba(255,255,255,1) 100%)`,
        }}
      />
      <header className="z-10 opacity-100 transition-opacity duration-500 group-hover:opacity-100 md:opacity-0">
        <h6 className="font-secondary text-base font-bold text-header md:text-2xl">
          {title}
        </h6>
        <div className="text-sm md:text-lg">{description}</div>
      </header>
    </Link>
  );
};

export default PortfolioItem;
