import './src/styles/global.scss';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdZye4hAAAAAP9S1Rlzdu9sCiAlLbmxqnN-dHjz">
      {element}
    </GoogleReCaptchaProvider>
  );
};
