import React from 'react';
import { Link as I18nLink } from 'gatsby-plugin-react-i18next';
import classNames from 'classnames';

function Link({ children, className, ...props }: any) {
  return (
    <I18nLink
      {...props}
      className={classNames(
        'hover:text-primary hover:underline hover:underline-offset-8',
        className
      )}
    >
      {children}
    </I18nLink>
  );
}

export default Link;
