import classNames from 'classnames';
import React from 'react';
import { InputWrapper } from '../input-wrapper';

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label: React.ReactNode;
  id: string;
}

export const Checkbox = React.forwardRef(
  (
    { className, label, ...rest }: CheckboxProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const { id } = rest;
    return (
      <InputWrapper
        label={label}
        className={classNames(
          'input-wrapper cursor-pointer !flex-row-reverse items-center',
          className
        )}
        labelClasses="pl-4 cursor-pointer"
        inputId={id}
      >
        <input
          type="checkbox"
          ref={ref}
          {...rest}
          aria-labelledby={`${id}-label`}
        />
      </InputWrapper>
    );
  }
);
