import React, { useCallback } from 'react';
import CookieIcon from './cookie.inline.svg';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

const COOKIE_NAME = 'cookies-accept';

export function CookieWarning() {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);
  const acceptCookies = useCallback(() => {
    setCookie(COOKIE_NAME, 'true');
  }, [setCookie]);
  const cookieValue = cookies[COOKIE_NAME];
  return cookieValue === 'true' ? null : (
    <div className="fixed bottom-0 z-50 w-full border-t-2 border-t-primary bg-white">
      <div className="mx-auto flex max-w-fw items-center gap-6 px-4 py-6 sm:px-6">
        <CookieIcon />
        <div>
          <h3 className="mb-0 text-lg font-bold md:mb-1.5 md:text-2xl">
            {t('components.cookie-warning.text-1')}
          </h3>
          <span className="text-sm font-medium md:text-lg">
            {t('components.cookie-warning.text-2')}
          </span>
        </div>
        <button className="button-primary" onClick={acceptCookies}>
          {t('components.cookie-warning.button')}
        </button>
      </div>
    </div>
  );
}
